import React from 'react';

const Stone: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="152"
      height="40"
      viewBox="0 0 152 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M85.7587 38.9654H92.5413C92.6073 38.9701 92.6735 38.9614 92.736 38.9399C92.7984 38.9184 92.8558 38.8845 92.9047 38.8403C92.9535 38.7961 92.9927 38.7426 93.02 38.6829C93.0473 38.6232 93.062 38.5586 93.0633 38.4931V19.9296C93.0633 14.6655 95.6729 11.8918 100.178 11.8918C104.684 11.8918 107.246 14.6655 107.246 19.9296V38.4955C107.247 38.561 107.262 38.6256 107.289 38.6852C107.316 38.7449 107.356 38.7985 107.404 38.8427C107.453 38.8869 107.511 38.9208 107.573 38.9423C107.636 38.9638 107.702 38.9725 107.768 38.9678H114.55C114.616 38.9725 114.682 38.9638 114.745 38.9423C114.807 38.9208 114.865 38.8869 114.914 38.8427C114.962 38.7985 115.002 38.7449 115.029 38.6852C115.056 38.6256 115.071 38.561 115.072 38.4955V19.9768C115.072 12.0323 110.614 5.07617 100.177 5.07617C89.7399 5.07617 85.2368 12.0323 85.2368 19.9768V38.4955C85.2384 38.5608 85.2534 38.6251 85.2808 38.6845C85.3082 38.7439 85.3475 38.7972 85.3963 38.8411C85.4451 38.8851 85.5023 38.9188 85.5646 38.9401C85.6269 38.9615 85.693 38.9701 85.7587 38.9654Z"
        fill="#00A868"
      />
      <path
        d="M39.0846 39.3895C41.5512 39.3895 43.1634 39.1073 43.8748 38.8251C43.9809 38.7831 44.0729 38.7122 44.1401 38.6207C44.2074 38.5292 44.2472 38.4208 44.255 38.3079V32.7581C44.255 32.4286 44.112 32.2409 43.6854 32.2409C43.3529 32.2409 42.4044 32.4286 41.6453 32.4286C38.7045 32.4286 37.4247 31.3482 37.4247 28.8094V13.1613H43.6854C43.8234 13.1601 43.9554 13.1052 44.053 13.0085C44.1507 12.9117 44.206 12.7809 44.2073 12.6441V6.58066C44.206 6.44387 44.1507 6.31304 44.053 6.21631C43.9554 6.11958 43.8234 6.0647 43.6854 6.06346H37.1875V0.799408C37.1875 0.23498 36.9492 0 36.5226 0H30.1189C29.5982 0 29.3611 0.236161 29.3611 0.799408V29.6112C29.3611 36.6158 33.297 39.3895 39.0846 39.3895Z"
        fill="#00A868"
      />
      <path
        d="M11.86 39.7645C19.497 39.7645 24.9522 35.1109 24.9522 29.0475C24.9522 22.2318 20.3979 20.2587 14.1849 18.9421L11.101 18.2844C8.77736 17.7672 7.49639 16.6855 7.49639 14.8529C7.49639 12.5031 9.86768 11.2337 13.141 11.2337C17.7418 11.2337 20.8722 13.3013 21.3464 13.3013C21.4782 13.2927 21.6052 13.2488 21.7139 13.1744C21.8226 13.0999 21.9089 12.9977 21.9637 12.8786L23.197 8.3195C23.4353 7.70784 23.3864 7.56732 22.9587 7.33234C20.2072 5.92245 17.2664 5.12305 13.0922 5.12305C5.36103 5.12305 0 9.54164 0 15.4174C0 20.9672 4.31719 24.0184 10.4861 25.3362L13.6165 25.9939C16.1308 26.5111 17.5488 27.4982 17.5488 29.6131C17.5488 31.7751 15.8412 33.655 11.8088 33.655C6.11651 33.655 2.22711 31.3996 1.61105 31.3996C1.23093 31.3996 1.08913 31.5874 0.947328 32.1979L0.140609 36.006C-0.0977125 36.8042 0.140609 37.3214 0.662532 37.5564C2.60842 38.6368 7.9206 39.7645 11.86 39.7645Z"
        fill="#00A868"
      />
      <path
        d="M63.6081 33.2314C58.6272 33.2314 54.0753 29.5178 54.0753 22.5144C54.0753 15.511 58.6284 11.8871 63.6081 11.8871C68.6355 11.8871 73.0945 15.5063 73.0945 22.5144C73.0945 29.565 68.6355 33.2314 63.6081 33.2314ZM63.6081 39.9998C73.5687 39.9998 80.8256 32.6209 80.8256 22.5144C80.8256 12.4078 73.5687 5.07617 63.6081 5.07617C53.6939 5.07617 46.3894 12.409 46.3894 22.5144C46.3894 32.6198 53.6939 39.9998 63.6081 39.9998Z"
        fill="#00A868"
      />
      <path
        d="M152 22.1389C152 11.7478 145.975 5.07617 136.39 5.07617C127.378 5.07617 119.599 11.939 119.599 22.3739C119.599 33.5136 127.567 39.9998 136.105 39.9998C143.268 39.9998 147.4 37.2025 149.956 33.7958C150.451 33.1358 150.552 32.8512 150.552 32.667C150.552 32.4828 150.433 32.3387 150.003 31.9585L147.014 29.3737C146.299 28.7632 146.061 28.5743 145.823 28.5743C145.584 28.5743 145.443 28.716 145.253 28.951C143.213 31.6775 140.368 33.2279 136.526 33.2279C131.68 33.2279 128.45 29.9653 127.631 24.6729H149.519C151.046 24.6765 152 24.2006 152 22.1389ZM136.109 11.6096C141.285 11.6096 144.025 15.0706 144.174 18.8952H127.746C128.646 14.7883 131.459 11.6096 136.109 11.6096Z"
        fill="#00A868"
      />
    </svg>
  );
};

export default Stone;
